* {
    padding: 0;
    margin: 0;
    font-family: 'Roboto';
    font-size: 14.7px
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: linear-gradient(circle, #87c5ed, #95ccef, #a3d2f2, #b0d9f4, #bde0f7);
    ;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-image: linear-gradient(to top, #87c5ed, #95ccef, #a3d2f2, #b0d9f4, #bde0f7);
    border-radius: 10px;
    border: 3px solid #ffffff;
}

:root {
    --primary-heading: #151D48;
    --secondary-heading: #737791;
    --primary-background-color: white;
    --second-primary-background-color: #3E4095;
    --primary-btn-background-color: #00AFF0;
    --primary-btn-text-color: white;
    --secondary-background-color: #F5F6F7;
    --primary-light-gray-color: #F5F6F7;
    --lightgray-color: #F5F6F7
}

fieldset.scheduler-border {
    border: 1px groove #ddd !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
}

legend.scheduler-border {
    font-size: 1.2em !important;
    font-weight: bold !important;
    text-align: left !important;
    width: auto;
    padding: 0 10px;
    border-bottom: none;
}

.pointer {
    cursor: pointer;
}

.image-div {
    position: relative;
    width: 250px;
    height: 200px;
    overflow: hidden;
    display: inline-block;
}

.image-div:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

.image-div:hover .div-btn {
    /* position: relative; */
    display: block;
    /* Show on hover */
}

.div-btn {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background-color: #e74c3c;
    /* Red color */
    color: #fff;
    /* White text color */
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    display: none;
    /* Initially hidden */
}

.div-btn:hover {
    background-color: #c0392b;
    /* Darker red on hover */
}

.div-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

a {
    text-decoration: none;
}

.rowBox {
    border-bottom: 0.6px solid #E4E4E4
}

.loader {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

.text-secondary {
    color: var(--secondary-heading) !important;
}

.text-primary {
    color: var(--primary-heading) !important;
}

.text-blue-color {
    color: var(--primary-btn-background-color) !important;
}

.pointer {
    cursor: pointer;
}

/* modal css*/
.modal-header {
    margin-top: 15px !important;
    border: none;
    height: 30px;
}

.btn-close {
    border: 3px solid var(--secondary-heading);
    width: 10px;
    height: 10px;
}

.modal-title {
    font-size: 1.5rem;
    font-weight: 600;
}

.modal-footer {
    border: none;
    /* padding-bottom: 5px; */
}

/* end modal */

/* .navbar {
    background-color: var(--primary-background-color) !important;
    border: none !important;
   border-bottom: 0.3px solid rgba(0, 0, 0, 0.3); 
    height: 75px !important;
    width: 100vw; 
     position: sticky; 
} */
.navbar {
    background-color: #fff !important;
    background-color: var(--primary-background-color) !important;
    border: 2px solid #e3e6e9 !important;
    height: 73px !important;
}

.brand-name {
    font-weight: 600;
    color: var(--primary-heading);
    text-decoration: none;
    font-size: 1.08rem;
}

.brand-tagline {
    font-weight: 400;
    color: var(--secondary-heading);
    text-decoration: none;
    font-size: 0.6rem;
}


.searchbox {
    position: relative;
    width: auto;

}

.searchbox input {
    min-width: 300px;
    padding-left: 30px;
    border: none !important;
    background-color: var(--lightgray-color);
    height: 45px;


}

.search-icon {
    margin-top: -32px;
    padding-left: 8px;
    position: absolute;
}

.filterbox {
    position: relative;
    width: auto;

}

.filterbox div {
    width: 60px;
    height: 45px;
    /* padding-left: 30px; */
}

.filter-icon {
    margin-top: -30px;
    /* padding-left: 8px; */
    position: absolute;
    margin-left: 58%;
    margin-right: 20px;
}

.avatar {
    width: 30px;
    height: 30px;
    border-radius: 20px;

}

.avatar img {
    width: 30px;
    height: 30px;
    border-radius: 20px;

}

/* navbar css end */

/* Side bar css Start */

.sidebar {
    display: flex;
    /* background-color: green !important; */
    width: 300px;
    min-height: 92.4vh;
    flex-direction: column;
    background-color: var(--primary-background-color);

}

/* .sider-li-ul {
    position: relative;
    background-color: #151D48;

} */
/* 
.sider-li-ul ul {
    position: absolute;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    max-height: 150px;
    min-height: 100px;
    margin-top: 160px;
    width: 88%;
    padding-left: 0;
    overflow: auto;
    display: none;
    z-index: 10;
    background-color: var(--primary-background-color) !important;
} */
/* 
.sider-li-ul ul .selected {


    display: block;

} */
/* 
.sider-li-ul ul li {
    height: 35px;


    border-radius: 10px;
    padding-left: 10px;
    align-items: center;
} */
/* 
.sider-li-ul ul li:hover {
    background-color: var(--secondary-heading);
    color: var(--primary-background-color);
    border-radius: 0px !important;
    width: 180px;
    border-radius: 10px;
} */


/* side bar css end  */
/* main content csss start  */
.main-content {
    background-color: var(--secondary-background-color);
    width: 100%;
}

.pageheading span {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 37.5px;
    color: var(--primary-heading);
}

.project-conatiner {

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    column-gap: 15px;
    row-gap: 15px;
    /* grid-template-rows: auto auto auto;  */


}


.card-title {
    color: var(--primary-heading);
    font-weight: 600;
    font-size: 1.03rem;
}

.card-refrence {
    font-size: 0.9rem;
    font-weight: 400;
    color: var(--secondary-heading);
}

.rupee-icon {
    color: white;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background-color: var(--primary-btn-background-color);
    width: 22px;
    height: 22px;
    font-size: 12px;
}


.price {
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 24px;
}

.btn-primary {
    background-color: var(--primary-btn-background-color) !important;
    color: var(--primary-btn-text-color) !important;
    border: none;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 27px !important;
}

/* end of main content css */

/* land css */
.activeTabmainBox {
    border-bottom: 0.2px solid var(--lightgray-color);
}

.activetab {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 0.6px solid var(--secondary-heading);
    /* background-color: var(--primary-btn-background-color); */
}

.activetabBox {
    border-bottom: 2px solid var(--primary-btn-background-color);
}

.selectedTab {
    background-color: var(--primary-btn-background-color);
    border: 1px solid var(--primary-btn-background-color);
}

.export-save-btn {
    background-color: var(--primary-btn-background-color);
    color: var(--primary-background-color);
    font-size: 1.1rem;
    font-weight: 500;
    list-style: 27px;
    border: 1px solid var(--primary-btn-background-color);
}

.export-save-btn:hover {
    background-color: var(--primary-background-color);
    color: var(--primary-btn-background-color);
    font-size: 1.1rem;
    font-weight: 500;
    list-style: 27px;
    border: 1px solid var(--primary-btn-background-color);

}

.selectedNameTag {
    color: var(--primary-btn-background-color);
}

.landformconatiner {
    background-color: var(--primary-background-color);
    max-height: 900px;
    border-radius: 15px;
    overflow: auto;

}
tr.table-head {
    border-width: 1px, 0px, 1px, 0px;
    border-top: 1px solid;
    background: #fafcff;
    border-color: #EDEDED;
    vertical-align: middle;
    height: 54px;
}

.landformconatinermaster {
    display: flex;
    background-color: var(--primary-background-color);
    max-height: 900px;
    border-radius: 15px;
    /* overflow: auto; */
    margin-left: 12px;
    width: 110em;
}
.btngroup {
    display: flex;
    margin-left: 415px;
}
label.btn.btn-Individual {
    padding: 9px;
    color: #00aff0;
    border: 1px solid #00aff0;
    border-radius: 8px;
}
label.btn.btn-project {
    padding: 9px;
    color: #00aff0;
    border: 1px solid #00aff0;
    border-radius: 8px;

}
.btn-check+.btn:hover {
    color: #00aff0;
    border: 1px solid #00aff0;
    background-color: var(--bs-btn-bg);
   
}
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
    background-color: var(--primary-btn-background-color);
    color: var(--primary-background-color);
    border-color: var(--bs-btn-active-border-color);
}

.savebtn {
    padding-top: 31px;
}

.wrapform {

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(370px, 5fr));
    column-gap: 15px;
    row-gap: 18px;
}

.form-label {
    font-size: 1rem;
    font-weight: 400;
    line-height: 16px;
    color: var(--primary-heading);
}

.form-input-box {
    max-width: 100%;
    height: 45px;
    border-radius: 5px;
    border: 0.5px solid var(--lightgray-color);
    background-color: var(--secondary-background-color);
    padding-left: 8px;
    padding-right: 8px;
    color: var(--secondary-heading);
}

.fileuploadbox {
    border: 1px dashed var(--second-primary-background-color);
    border-radius: 10px;
    min-height: 200px;
    background-image: linear-gradient(to top, #c6e9ff, #daedff, #eaf2ff, #f7f8ff, #ffffff);
    position: relative;
}

.bi-cloud-upload-fill {
    font-size: 2.5rem !important;
    background-image: linear-gradient(to top, #aed6f0, #b8dbf2, #c2e0f4, #cce6f7, #d6ebf9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* color: blue */
}

.gardiantext {
    font-size: 1.2rem !important;
    font-weight: 600;
    background-image: linear-gradient(to top, #87c5ed, #95ccef, #a3d2f2, #b0d9f4, #bde0f7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.fileinput {
    /* display: none; */
    position: absolute;
    /* z-index: -1 !important; */
    width: 100%;
    height: 100%;
    background: transparent;
    opacity: 0;
}

/* end land css */

/* project dashbaord css */
.expenses-card {
    min-height: 140px;
    /* min-width: 180px; */
    border-radius: 10px;

}

.expenses-conatiner {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    column-gap: 15px;
    row-gap: 15px;
}

.projecticon {
    background-color: white;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 20px;

}

.proj-heading {
    font-weight: 500;
    font-size: 1rem;
    line-height: 24px;
    color: var(--primary-background-color);
}

.proj-cost {
    font-weight: 600;
    font-size: 1.63rem;
    line-height: 24px;
    color: var(--primary-background-color);
}
.proj-subheading{
    font-weight: 500;
    font-size: 0.83rem;
    line-height: 8px;
    color: var(--primary-background-color);
}

.editProject {
    width: 90px;
    border: 0.2px solid var(--lightgray-color);
    border-radius: 5px;
    background-color: var(--primary-background-color);
    font-size: 0.7rem;
}

.locationIcon {
    width: 25px;
    height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--primary-heading);
    color: var(--primary-heading);
    border-radius: 15px;
}

.locationtext {
    font-size: 1rem;
    font-weight: 600;
    line-height: 24px;
    color: var(--primary-heading);
}

.project-icon {
    background-color: var(--primary-btn-background-color);
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--primary-background-color);
    font-weight: 800;
    font-size: 1rem;
    border-radius: 15px;
}

.heading-project {
    font-size: 1rem;
    font-weight: 500;
    line-height: 30px;
    color: var(--primary-heading);
}

.sub-heading-project2 {
    font-size: 1rem;
    margin-top: -3px;
    color: green
}

.sub-heading-project {
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 16px;
    color: #737791;
}

.add-expess {
    background-color: var(--primary-light-gray-color);
    padding: 15px;
    border-radius: 15px;
}

/* css project dashboard end */
/* login css */
.loginimage {
    background-image: url('../images/LoginImage.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;

}

.login-heading-main {
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 60px;
    color: var(--primary-heading);
}

.login-sub-heading {
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 24px;
    color: var(--secondary-heading);
}

.textboxcontainer {
    position: relative;
}

.emailicon {
    position: absolute;
    font-size: 22px;
    padding-left: 12px;
    padding-top: 8px;
}

.passwordlabel {
    font-size: 1rem;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: var(--second-primary-background-color);
}

.login-btn {
    background-color: var(--second-primary-background-color);
    color: var(--primary-background-color);
    font-size: 1.1rem;
    font-weight: 500;
    list-style: 27px
}

.login-btn:hover {
    background-color: var(--primary-background-color);
    color: var(--second-primary-background-color);
    font-size: 1.1rem;
    font-weight: 500;
    list-style: 27px;
    border: 0.5px solid var(--secondary-heading);
}

.login-other {
    background-color: var(--primary-background-color);
    color: var(--primary-heading);
    font-size: 1.1rem;
    font-weight: 600;
    list-style: 27px;
    border-radius: 5px;
    border: 0.5px solid var(--secondary-heading);
}

.checkboxlogin {
    accent-color: #9d3039 !important;
}

.login-other:hover {
    background-color: var(--primary-heading);
    color: var(--primary-background-color);
    font-size: 1.1rem;
    font-weight: 600;
    list-style: 27px;
    border-radius: 5px;
    border: 0.5px solid var(--secondary-heading);
}

/* login css end*/

/* table css */
.table-head th {
    color: var(--primary-heading);
}

.table-body tr {
    height: 71px;
}

.table-body tr td {
    font-size: 1rem;
    font-weight: 500;
    line-height: 24px;
    vertical-align: middle;
}

.table-body tr:hover {

    background-color: var(--lightgray-color);

}

.table-container {
    overflow-x: auto;
}

/* table css end */
/* display css */
.display-container {
    max-height: 400px;
    overflow-y: auto;
}

/* display css end */
/* collapse css */
.land-info-collapse {
    background-color: var(--primary-background-color);
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
    height: 60px;
    border-radius: 10px;
}

.land-collapse-text {
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 18px;
    color: var(--primary-btn-background-color);
}

/* collapse css end */
/* media Query */
@media (min-width: 320px) and (max-width: 768px) {
    .sidebar {
        display: none;
        background-color: var(--primary-background-color);
        width: 250px;
        min-height: 92.4vh;
        flex-direction: column;
    }

    .wrapform {


        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 5fr));
        column-gap: 15px;
        row-gap: 18px;
    }

}

@media (min-width: 769px) and (max-width: 991.98px) {
    .sidebar {
        display: block;
        background-color: var(--primary-background-color);
        width: 230px;
        min-height: 92.4vh;
        flex-direction: column;
    }

    .wrapform {

        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 5fr));
        column-gap: 15px;
        row-gap: 18px;
    }
}

@media (min-width: 992px) and (max-width: 1024px) {
    /* .sidebar {
        display: block;
        background-color: var(--primary-background-color);
        width: 230px;
        min-height: 92.4vh;
        flex-direction: column;
    } */

    .wrapform {

        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 5fr));
        column-gap: 15px;
        row-gap: 18px;
    }
}

@media (min-width: 1025px) {
    /* .sidebar {
        display: block;
        background-color: var(--primary-background-color);
        width: 230px;
        min-height: 92.4vh;
        flex-direction: column;
    } */

    .wrapform {


        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(370px, 5fr));
        column-gap: 15px;
        row-gap: 18px;
    }
}



/* animation */

@keyframes opensidebaranimation {
    0% {
        width: 0;
    }

    20% {
        width: 50px;
    }

    40% {
        width: 100px;
    }

    60% {
        width: 150px;
    }

    80% {
        width: 200px;
    }

    100% {
        width: 250px;
    }

}

@keyframes closesidebaranimation {
    0% {
        width: 250px;
    }

    20% {
        width: 200px;
    }

    40% {
        width: 150px;
    }

    60% {
        width: 100px;
    }

    80% {
        width: 50px;
    }

    100% {
        width: 0px;
    }

}

@keyframes openfullsidebaranimation {
    0% {
        width: 80px;
    }

    20% {
        width: 100px;
    }

    40% {
        width: 150px;
    }

    60% {
        width: 180px;
    }

    80% {
        width: 210px;
    }

    100% {
        width: 250px;
    }

}

@keyframes closefullsidebaranimation {
    0% {
        width: 250px;
    }

    20% {
        width: 220px;
    }

    40% {
        width: 200px;
    }

    60% {
        width: 180px;
    }

    80% {
        width: 120px;
    }

    100% {
        width: 80px;
    }

}







.sidebar::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: var(--primary-backgorund);
}

.sidebar::-webkit-scrollbar-thumb {
    background-color: #aab7cf;
}



.sidebar-nav {
    padding: 10px;
    margin: 0;
    list-style: none;

}

.sidebar-nav li {
    padding-top: 5px;
    margin-top: 3px;
    list-style: none;


    /* background-color: var(--primary-background-color); */
    color: var(--secondary-heading);
    border-radius: 15px;

}



.sidebar-nav .selected svg path {
    fill: var(--primary-background-color);
}

.sidebar-nav .nav-item {
    margin-bottom: 5px;
}

.sidebar-nav .nav-heading {
    font-size: 1rem;
    /* text-transform: uppercase; */
    color: rgb(115, 119, 145);
    font-weight: 500;
    padding-top: 10px;
    /* margin: 10px 0 5px 15px; */
}

.sidebar-nav .nav-link {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    color: rgb(115, 119, 145);
    transition: 0.3;
    background: var(--primary-background-color);
    padding: 12px;
    border-radius: 12px;
    gap: 12px;
}

.sidebar-nav .nav-link svg path {
    font-size: 16px;
    margin-right: 10px;
    color: var(--primary-color);
}

.sidebar-nav .nav-link.collapsed {
    color: var(--seconday-color);
    background: var(--primary-backgorund);
}

.sidebar-nav .nav-link.collapsed svg path {
    color: #899bbd;
}

.sidebar-nav .nav-link:hover {
    /* color: var(--primary-color);
  background: #f6f9ff; */
    background-color: var(--second-primary-background-color);
    color: var(--primary-background-color);
}

.sidebar-nav .selected {
    background-color: var(--second-primary-background-color);
    color: var(--primary-background-color);
}


.sidebar-nav .nav-link:hover svg path {
    /* color: var(--primary-color); */
    fill: var(--primary-background-color);
}

.sidebar-nav .nav-link .bi-chevron-down {
    margin-right: 0;
    transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
    transform: rotate(180deg);
}

.sidebar-nav .nav-content {
    padding: 5px 0 0 0;
    margin: 0;
    list-style: none;
}

.sidebar-nav .nav-content a {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    color: var(--seconday-color);
    transition: 0.3;
    padding: 10px 0 10px 40px;
    transition: 0.3s;
}

.sidebar-nav .nav-content a:hover {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    color: var(--primary-background-color) !important;
    background-color: rgb(128, 128, 128);
    border-radius: 12px;
    transition: 0.3;
    padding: 10px 0 10px 40px;
    transition: 0.3s;
}

.sidebar-nav .nav-content a i {
    font-size: 6px;
    margin-right: 8px;
    line-height: 0;
    border-radius: 50%;
}

.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active {
    color: var(--primary-color);
}

.sidebar-nav .nav-content a.active i {
    background-color: var(--primary-color);
}

.sav-circle {
    width: 60px;
    height: 60px;
    border: 3px solid red;
    border-radius: 30px;

}


.revenue-container{
    max-width: 330px;
    height: 120px;
    background-color: rgba(255, 84, 172, 0.2);
    border-radius: 10px;
    padding: 20px;
}

.transaction-tab{
    border:1px solid #737791 !important;
    color: #737791 !important;
    background-color: white !important;
}
.transaction-tab-selected{
    border:1px solid #00AFF0 !important;
    color: white !important;
    background-color: #00AFF0 !important;
}